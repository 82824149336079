<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <v-container>
      <div class="d-flex flex-row align-center justify-space-between mb-4">
        <div class="top-header mb-0 text-uppercase">
          <v-icon class="cursor--pointer" @click="backCompanies"
            >mdi-reply</v-icon
          >

          {{ "Companies" }}
        </div>
      </div>
      <v-card>
        <v-toolbar class="primary" tag="div" flat dark tile>
          <div class="text-h6">
            Company States: {{ company ? company.name : "" }}
          </div></v-toolbar
        >

        <v-card-text>
          <div class="d-flex">
            <v-spacer></v-spacer>
            <div>
              <div class="text-center">New State-Price</div>
              <v-divider class="my-2"></v-divider>
              <div class="d-flex">
                <div>
                  <v-autocomplete
                    :loading="loadingStates"
                    label="State"
                    v-model="newState"
                    item-text="name"
                    item-value="id"
                    outlined
                    dense
                    :items="states"
                  ></v-autocomplete>
                </div>
                <div class="mx-2">
                  <v-text-field
                    prepend-inner-icon="mdi-currency-usd"
                    label="Price"
                    v-model="newAmount"
                    outlined
                    dense
                  ></v-text-field>
                </div>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      :loading="loading"
                      :disabled="loading || !validForm"
                      v-on="on"
                      color="primary"
                      depressed
                      @click="addState"
                      ><v-icon>mdi-plus</v-icon></v-btn
                    >
                  </template>
                  <span>Add State</span>
                </v-tooltip>
              </div>
            </div>
          </div>
          <template>
            <v-data-table
              :headers="headers"
              :items="statesCompany"
              :items-per-page="10"
              item-key="name"
              class="elevation-1"
              :loading="loading"
            >
              <template v-slot:[`item.state`]="{ item }">
                {{ item.state ? item.state.name : "-" }}
              </template>
              <template v-slot:[`item.price`]="{ item }">
                {{ item.price | currency }}
              </template>
              <template v-slot:[`item.createdAt`]="{ item }">
                {{ getDate(item.createdAt) }}
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" color="primary" icon @click="toEdit(item)"
                      ><v-icon>mdi-pencil</v-icon></v-btn
                    >
                  </template>
                  <span>Edit State-Price</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" color="error" icon @click="toDelete(item)"
                      ><v-icon>mdi-delete</v-icon></v-btn
                    >
                  </template>
                  <span>Delete State-Price</span>
                </v-tooltip>
              </template>
            </v-data-table>
            <v-dialog
              v-model="dialogEdit"
              persistent
              :overlay="false"
              max-width="350px"
              transition="dialog-transition"
            >
              <v-card>
                <v-toolbar class="primary" tag="div" flat dark tile>
                  <div class="text-h6">
                    Edit Price: {{ currentItem ? currentItem.state.name : "-" }}
                  </div></v-toolbar
                >
                <v-card-text class="mt-4">
                  <v-text-field
                    v-model="priceEdit"
                    prepend-inner-icon="mdi-currency-usd"
                    label="Price"
                    outlined
                    dense
                  ></v-text-field>
                  <div class="d-flex">
                    <v-spacer></v-spacer>
                    <v-btn color="error" text @click="cancelAdd">Cancel</v-btn>
                    <v-btn
                      color="primary"
                      :loading="loading"
                      :disabled="loading || priceEdit == 0 || priceEdit == null"
                      text
                      @click="confirmEdit()"
                      >Edit</v-btn
                    >
                  </div>
                </v-card-text>
              </v-card>
            </v-dialog>
            <v-dialog
              v-model="dialogDelete"
              persistent
              :overlay="false"
              max-width="350px"
              transition="dialog-transition"
            >
              <v-card>
                <v-toolbar class="error" tag="div" flat dark tile>
                  <div class="text-h6">
                    Delete Company State:
                    {{ currentItem ? currentItem.state.name : "-" }}
                  </div></v-toolbar
                >
                <v-card-text class="mt-4">
                  <div class="text-center my-2">Are you sure?</div>
                  <div class="d-flex">
                    <v-spacer></v-spacer>
                    <v-btn text @click="cancelAdd">Cancel</v-btn>
                    <v-btn
                      color="error"
                      :loading="loading"
                      :disabled="loading"
                      text
                      @click="confirmDelete()"
                      >Delete</v-btn
                    >
                  </div>
                </v-card-text>
              </v-card>
            </v-dialog>
          </template>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>
<script>
import { getAPI } from "@/api/axios-base";
import { mapActions, mapState } from "vuex";
import {
  notifyError,
  notifyInfo,
  notifySuccess,
} from "@/components/Notification";
import { utilMixin } from "@/utils/mixins";
export default {
  name: "states-company",
  mixins: [utilMixin],
  data() {
    return {
      statesCompany: [],
      newState: null,
      newAmount: 0,
      loading: false,
      headers: [
        { text: "State", align: "start", value: "state", sortable: true },
        { text: "Price", align: "end", value: "price", sortable: true },
        {
          text: "Create Date",
          align: "end",
          value: "createdAt",
          sortable: true,
        },
        /*  { text: "Color", value: "color", sortable: false }, */
        //  { text: "Default", value: "default", sortable: false },
        { text: "", align: "end", value: "actions", sortable: false },
      ],

      dialogEdit: false,
      priceEdit: 0,
      currentItem: null,
      dialogDelete: false,
      company: null,
    };
  },
  computed: {
    ...mapState("crmMedicFormModule", ["states", "loadingStates"]),
    validForm() {
      if (this.newAmount == 0 || this.newState == null) {
        return false;
      }
      return true;
    },
  },
  methods: {
    ...mapActions("crmMedicFormModule", ["actListStates"]),
    addState() {
      const id = Number(this.$route.params.id);
      const body = {
        companyId: id,
        stateId: this.newState,
        price: Number(this.newAmount),
      };
      this.loading = true;
      getAPI
        .post("/company-state-price/create", body)
        .then((res) => {
          this.cancelAdd();
          notifySuccess(`Company State has been added`);
          this.getStatesPrices();
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    confirmEdit() {
      this.loading = true;
      const price = Number(this.priceEdit);
      getAPI
        .post("/company-state-price/update/" + this.currentItem.uuid, {
          price,
        })
        .then((res) => {
          this.cancelAdd();
          notifyInfo(`Price has been updated`);
          this.getStatesPrices();
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    confirmDelete() {
      this.loading = true;

      getAPI
        .delete("/company-state-price/" + this.currentItem.uuid)
        .then((res) => {
          this.cancelAdd();
          notifyInfo(`Company State has been deleted`);
          this.getStatesPrices();
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    getStatesPrices() {
      const id = Number(this.$route.params.id);
      this.loading = true;
      getAPI
        .post("/company-state-price/filterList", { companyId: id })
        .then((res) => {
          this.loading = false;
          this.statesCompany = res.data;
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    cancelAdd() {
      this.newAmount = 0;
      this.newState = null;
      this.loading = false;
      this.currentItem = null;
      this.dialogEdit = false;
      this.dialogDelete = false;
      this.priceEdit = 0;
    },
    backCompanies() {
      this.$router.push({ name: "Companies Nom" });
    },

    toEdit(item) {
      this.currentItem = item;
      this.priceEdit = item.price;
      this.dialogEdit = true;
    },
    toDelete(item) {
      this.currentItem = item;
      this.dialogDelete = true;
    },

    getcompany() {
      const id = this.$route.params.id;
      getAPI("/company-nom/" + id).then((res) => {
        this.company = res.data;
      });
    },
  },
  mounted() {
    this.getcompany();
    this.actListStates();
    this.getStatesPrices();
  },
};
</script>
<style lang="scss" scoped></style>
